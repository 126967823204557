import React from "react";
function Footbar(props) {
  return (
    <li>
    <a  className="footer-link" href={props.url}>
      <i className={props.faicon} />
    </a>
    </li>
  );
}

export default Footbar;

