import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import "../styles.css";
import blogitems from "./blogs";
import { Blogbody } from "./blogstack";
import Footer from "./Footer";
import Header from "./Header";
const TITLE = "Lets Confer | Blogs";
function Blog(match) {
  var xid = match.params.id;
  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header />
      <div className="layout">
        {/* Header Unit Started */}
        {/* eslint-disable-next-line array-callback-return */}
        {blogitems.map(function (item, index) {
          if (item.xid === xid) {
            return (
              <Blogbody
                key={index}
                name={item.name}
                xid={item.xid}
                url={item.url}
                blogdate={item.blogdate}
                bigimage={item.bigimage}
                arialabel={item.arialabel}
                content={item.content}
                video={item.video}
                hashtag={item.hashtag}
              />
            );
          }
        })}
      </div>

      <Footer />
    </div>
  );
}
export default Blog;
