import $ from "jquery";
import React from "react";
function showImage(id, image) {
  var id1 = "#" + id;
  $(id1).attr("src", image);
}
function Blogstack(props) {
  return (
    <a href={"/blogs/" + props.url}>
      <img
        className="small-image"
        src={props.images2}
        alt={props.arialabel}
        id={props.xid}
        onMouseOver={() => showImage(props.xid, props.images)}
        onMouseOut={() => showImage(props.xid, props.images2)}
      />
    </a>
  );
}
function Blogbody(props) {
  let result = '';
  props.content.forEach(letter => {
  result += letter;
});
  var markup = { __html: result};
  return (
    <div>
      <p className="blog-dt badge badge-warning">{props.blogdate}</p>
      <h1 className="title_div">{props.name}</h1>
      {props.hashtag.map((value, index) => {
        return (
          <span key={index} className="blog-hash badge badge-primary">
            {value}
          </span>
        );
      })}
      <img
        className="blog-image"
        src={"/images/blogs/" + props.bigimage}
        alt={props.arialabel}
        id={props.name}
      />
      <div className="blogbody">
        <span dangerouslySetInnerHTML={markup} />
      </div>
      <hr />
      <iframe className="blog-video" allowFullScreen src={props.video}>
      </iframe>
    </div>
  );
}

export default Blogstack;
export { Blogbody };


