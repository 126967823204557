import 'bootstrap/dist/js/bootstrap.bundle.min';
// Relative imports
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import About from './components/About';
import App from './components/App';
import Blog from './components/Blog';
import Solution from './components/Solution.jsx';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
const MainPage = () => (
  <App />
);

const BlogPage = ({ match }) => (
  Blog(match)
);

const SolutionPage = () => (
  <Solution />
);


const HomedataPage = ({ match }) => (
  <h1>homedata</h1>
);

const AboutPage = ({ match }) => (
  <About />
);
const NoMatch = () => (
  <p>Invalid route: try <a href="https://wwww.subhdb.com/"> https://www.subhdb.com/</a></p>
);

const RoutedApp = () => (
  <BrowserRouter >
    <Switch>
      <Route path="/homedata/:id" component={HomedataPage} />
      <Route path="/homedata" component={HomedataPage} />
      <Route path="/solutions" component={SolutionPage} />
      <Route path="/blogs/:id" component={BlogPage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/" component={MainPage} />
      <Route exact path="/" render={() => (<Redirect to="/" />)} />
      <Route path="*" component={NoMatch} />
    </Switch>
  </BrowserRouter>
);
ReactDOM.render(<RoutedApp />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
