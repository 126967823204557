import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import "../styles.css";
import Footer from "./Footer";
import Header from "./Header";
import Topselection from "./Topsection";


const TITLE = "Lets Confer";

function App() {

  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="App-header">
        {/* Header Unit Started */}
        <Header />
        <Topselection />
      </div>
      <Footer />
    </div>
  );
}
export default App;
