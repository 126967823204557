import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import "../styles.css";
import Footer from "./Footer";
import Header from "./Header";
import Skill from "./Skill";
import skills, { functional } from "./skillitem";

const TITLE = "Lets Confer | About me";

function About() {
  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="layout">
        <Header />
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Summary</h5>
              <p className="card-text">
                I am, CSCP (supply chain) certified; IOT (internet of things
                certified from AXELTA) and Machine Learning (Certified from
                Stanford University and IBM) certified, Oracle E-business and
                OTM certified - Full Stack Solution Architect with 21 plus years
                of experience, with proven expertise in –
                <li>
                  Large Program Management in the field of Oracle and several
                  digital technologies that includes ERP, SCM Products (OTM,
                  WMS, ASCM, Demantra) implementations, Upgrades and their Life
                  cycle management
                </li>
                <li>
                  Advisory services in the area of - Product evaluations & GAP
                  fitment study, Build vs Buy analysis, ROI Analysis, Business
                  Process Study and Design, Business process re-engineering
                </li>
                <li>
                  Expert in Data center, Oracle Apps instance consolidation,
                  Database & UNIX administration, Infrastructure capacity
                  planning and in performance tuning.
                </li>
                <li>
                  Huge experience in Building new products/ solutions/ assets
                  around E-business, OTM and in latest key Digital Technologies
                  that includes - IoT, Blockchain, Mobile & Web App, Chatbot,
                  ML/AI etc.
                </li>
                I have work experiences in following overseas countries –
                Australia, USA, Tanzania, Ireland, Switzerland, Hungary, Brazil,
                Singapore and UAE.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Technical Skills</h5>
              <div className="card-text">
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Skill Details</th>
                      <th>Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skills.map(function(item, index) {
                      return (
                        <Skill
                          key={index}
                          Area={item.Area}
                          Skill={item.Skill}
                          Score={item.Score}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Functional Skills</h5>
              <div className="card-text">
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Skill Details</th>
                      <th>Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {functional.map(function(item, index) {
                      return (
                        <Skill
                          key={index}
                          Area={item.Area}
                          Skill={item.Skill}
                          Score={item.Score}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Customer Relationship</h5>
              <div className="card-text">
                <img src="/images/customer.png" alt="global customers" />
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Oracle E-business & OTM solutions</h5>
              <div className="card-text">
                <li>
                  Oracle EAM & Field Service Integration solutions for utility
                  companies (Linear asset management solutions) - Year 2015
                </li>
                <li>
                  Integrated Mine operations planning & scheduling tool
                  integration with Oracle EAM (Opencast & Underground mines) -
                  Year 2010
                </li>
                <li>
                  Oracle Apps Instance Consolidation Tool - Year 2002, and
                  modified in 2012
                </li>
                <li>
                  Oracle Apps Upgrade Impact Assessment tool Year 2002,
                  re-modified 2015
                </li>
                <li>
                  Oracle Apps Capacity Sizing Tool - Year 2005, re-modified 2015
                </li>
                <li>Automated OPM MAC to ADR conversion - Year 2014</li>
                <li>
                  ERP Audit Portal (SOX compliance) to detect ERP vulnerability
                  and SOX violations & remediation (200+ scripts) - Year 2013
                </li>
                <li>
                  Oracle Apps DBA Monitoring & Patch-clone automation Toolkit –
                  for Monitoring/debug/troubleshooting/tracing (300+ scripts) –
                  Year 2001 to 2004
                </li>
                <li>
                  RICE Factory Demand & Capacity Management Tool – this is a
                  portal developed for ATP checks on new work packs, service
                  catalogs & estimation - Year 2014
                </li>
                <li>
                  OTM with non-oracle SSO/LDAP integration solutions - Year 2011
                </li>
                <li>OTM Rate Loader - Year 2011</li>
                <li>
                  OTM Financial Portal for Financial integrations - Year 2011
                </li>
                <li>
                  Custom E-business mobile apps using Oracle Jet Cordova (Hybrid
                  solution) - year 2017
                </li>
                <li>
                  E-biz REST service invocation using free opensource tech stack
                  like NodeJs (Oracledb) – year 2017
                </li>
                <li>
                  Custom system Admin data related – Endeca Discovery Dashboards
                  and integrated with Oracle Apps – year 2016
                </li>
                <li>Move order IOT solutions for DWTC – year 2017</li>
                <li>
                  OTM Dynamic MAP and OTM with Blockchain IOT cloud integration
                  – year 2018
                </li>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
          <div className="card">
          <div className="card-body">
            <h5 className="card-title">My Passions</h5>
            <div className="card-text">
              <div>
                <li> Music, Playing Guitar and Sing</li>
                <li> Photography & Painting</li>
                <li> Writing Poem and Drama </li>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated :  10th March,2020</small>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default About;
