import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import "../styles.css";
import Footer from "./Footer";
import Header from "./Header";
import Solutions from "./Solutions";
import solutionitems from "./solution";
const TITLE = "Lets Confer | Solution me";
function Solution() {
  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div>
        {/* Header Unit Started */}
        <Header />
        <div className="container-fluid ">
          <div className="card-deck-wrapper">
            <div className="row mt-4 mb-lg-5 mb-md-4 solutions-card-deck">
              <div className="container solutions-card-deck-container row mx-auto">
                {solutionitems.map(function (item, index) {
                  return (
                    <Solutions
                      key={index}
                      id={item.id}
                      name={item.name}
                      desc={item.desc}
                      blogtype={item.blogtype}
                      img_src={item.img_src}
                      img_src1={item.img_src1}
                      img_src2={item.img_src2}
                      deckname={item.deckName}
                      blogdate={item.blogdate}
                      videoname={item.videoName}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
   
  <div id="solutionDetailsSidenav" className="sidenav" style={{display: "none"}}></div>
  <div className="container-fluid">
    <div className="modal fade" id="solutionDeckModal">
      <div className="modal-dialog modal-xl h-100">
        <div className="modal-content">
          <div className="modal-header text-black-50">
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body">
            <div id="pdfRenderer" className="h-100 w-100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid">  
  <div className="modal fade" id="solutionVideoModal">
      <div className="modal-dialog modal-xl h-100">
        <div className="modal-content">
          <div className="modal-header text-black-50">
            <h4 className="modal-title text-black-50" id="solutionVideoModalHeading">Video</h4>
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body">
            <div className="iframe-container">
              <iframe loading="lazy" className="responsive-iframe w-100" id='solutionVideoModalIframe' src='' title="videoFrame"
                allowFullScreen   rel='nofollow' style={{border: "0px"}}></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <Footer />
    </div>
  );
}
export default Solution;
