const menuitems = [
  {
    name: "Home",
    url: "/",
    faicon: "fa fa-home",
    extraclass: "nav-link  active"
  },
  {
    name: "Solutions",
    url: "/solutions",
    faicon: "fa fa-th-list",
    extraclass: "nav-link"
  },
  {
    name: "About Me",
    url: "/about",
    faicon: "fa fa-plug",
    extraclass: "nav-link"
  }
];
export default menuitems;