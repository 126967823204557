import React from "react";

function Skill(props) {
  return (
    <tr>
    <td>{props.Area}</td>
    <td>{props.Skill}</td>
    {/* eslint-disable-next-line */}
    <td>{props.Score}</td>
  </tr>
  );
}

export default Skill;
