import React from "react";
import $ from "jquery";
function openSolutionDetailsSideNav(name, deckName, videoName, blogdate, img_src1, img_src2, id) {
  document.getElementById('solutionDetailsSidenav').style.display = '';
  document.getElementById('solutionDetailsSidenav').style.width = '319px';
  let isDeckDisabled = deckName ? 'false' : 'true';
  let deck_disabled_btn = 'deck_disabled_btn-' + isDeckDisabled;
  let isVideoDisabled = videoName ? 'false' : 'true';
  let video_disabled_btn = 'video_disabled_btn-' + isVideoDisabled;

  $('#solutionDetailsSidenav').html("")
  $('#solutionDetailsSidenav').append(`
  <script>
  function showImage(id, image) {
    document.getElementById(id).src = image;
  }
  function closeSideNav() {
    document.getElementById('solutionDetailsSidenav').style.width = '0px';
    document.getElementById('solutionDetailsSidenav').style.display = 'none';
  }
  function openSolutionDeckModal(name) {
    if (!name) {
      return
    }
    PDFObject.embed(name, "#pdfRenderer");
    $('#solutionDeckModal').modal('show');
  }
  function openSolutionVideoModal(name) {
    if (!name) {
      return
    }
    document.getElementById('solutionVideoModalIframe').setAttribute('src', name);
    $('#solutionVideoModal').modal('show');
  }
  </script>
  <div class="d-flex-column share-div" onclick="closeSideNav()">
  <img loading="lazy" src="/images/exit.jpg" style="width:5vw" />
  </div>
  <br/>
  <div class="title_div">${name}</div>
    <div class="solutionDetailsSidenav_desc_div">
     <p> Blog Date: <span>${blogdate}</span> </p><br>
      <img id="${id}" loading="lazy" class="small-image2" src="${img_src1}" 
      onmouseover = "showImage(${id},'${img_src2}')"
      onmouseout  = "showImage(${id},'${img_src1}')"  
      />
    </div>
    <hr>
   <div style="display:flex">
      <div class="deck-div ${deck_disabled_btn} " style="width:50%" onclick="openSolutionDeckModal('${deckName}')">
        <img loading="lazy" src="/images/deck.png" alt=""  style="height:5vmin" />
      </div>
      <div class="video-div ${video_disabled_btn}" style="width:50%"   onclick="openSolutionVideoModal('${videoName}')">
        <img loading="lazy" src="/images/video-files.png" alt="" style="height:5vmin"/>
      </div>
   </div>
   <div style="display:flex">
   <div class="deck-div1" style="width:50%">
     PDF
   </div>
   <div class="video-div1" style="width:50%">
     Video
   </div>
</div>
  `)
}
function Solutions(props) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12 mb-4">
      <div className="card h-100">
        <img loading="lazy" className="card-img-top w-100 d-block h-50" src={props.img_src} alt="" />
        <div className="card-body"><button className="btn btn-primary" type="button"
          id="carousel_card_button"
          onClick={() => openSolutionDetailsSideNav(props.name, props.deckname, props.videoname, props.blogdate, props.img_src1, props.img_src2, props.id)}>
          View Details
        </button>
          <h5>{props.name}</h5>
          <br/>
          <p className="card-text solution-card-text">
            {props.desc}
          </p>

        </div>
      </div>
    </div>
  );
}
export default Solutions;
