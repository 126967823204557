const footeritems = [
  {
    name: "linkedin",
    url: "https://in.linkedin.com/in/subhendudb",
    faicon: "fa fa-linkedin-square fa-lg"
  },
  {
    name: "facebook",
    url: "https://www.facebook.com/subhendu.dattabhowmik",
    faicon: "fa fa-facebook-square fa-lg"
  },
  {
    name: "mail",
    url: "mailto:subhendu.dattabhowmik@gmail.com",
    faicon: "fa fa-envelope fa-lg"
  },
  {
    name: "mapsite",
    url: "/map.html",
    faicon: "fa fa-map-marker fa-lg"
  },
  {
    name: "mapsite",
    url: "https://www.subhdb.com",
    faicon: "fa fa-cube fa-lg"
  }
];

export default footeritems;
