import React, { useState } from "react";
import Typed from "react-typed";
import blogitems from "./blogs";
import Blogstack from "./blogstack";
import Footbar from "./Footbar";
import footeritems from "./Footeritem";
function Topsection() {
   /* digital clock example of state and hooks*/
   setInterval(updateTime, 1000);
   const now = new Date().toLocaleTimeString();
   const [time, setTime] = useState(now);
   function updateTime() {
     const newTime = new Date().toLocaleTimeString();
     setTime(newTime);
   }
   var hrs = new Date().getHours();
   var greet;
   if (hrs < 12) greet = "GOOD MORNING";
   else if (hrs >= 12 && hrs <= 17) greet = "GOOD AFTERNOON";
   else if (hrs >= 17 && hrs <= 24) greet = "GOOD EVENING";

  // eslint-disable-next-line array-callback-return
  return (
    <section className="top-container container-fluid">
    <div className="row">
      <div className="col"></div>
      <div className="col">
        <span className="bwhite">
          {" "}
          {greet} : {time}
        </span>
      </div>
    </div>
    <div className="row">
      <div className="col"></div>
      <div className="col">
        {/*  eslint-disable-next-line */}
        <h4 className="bwhite">
          I'M SUBHENDU ▶️
          <Typed
            strings={[
              " FULLSTACK DEVELOPER",
              " APPLICATION DESIGNER",
              " CSCP CERTIFIED SCM SME",
              " DIGITAL ARCHITECT",
              " UNIX & DB ADMIN",
              " ML/AI & IOT ARCHITECT"
            ]}
            typeSpeed={80}
            backSpeed={100}
            loop
          />
        </h4>
      </div>
    </div>
    <div className="row">
    <div className="col"></div>
      <div className="col">
      <div className="text-bottom bwhite">
        <ul className="list-unstyled list-social" >
          {footeritems.map(function(item, index) {
            return (
              <Footbar
                key={index}
                name={item.name}
                url={item.url}
                faicon={item.faicon}
              />
            );
          })}
        </ul>
        </div>
      </div>

    </div>
    <div className="row">
      <div className="col">
        {blogitems.map(function(item, index) {
          return (
            <Blogstack
              key={index}
              xid={item.xid}
              url={item.url}
              images={item.images}
              images2={item.images2}
              arialabel={item.arialabel}
            />
          );
        })}
      </div>
    </div>
    <div className="row">
      <div className="col">
      </div>
      </div>
  </section>
 
  );
}

export default Topsection;
