import React from "react";
import Menubar from "./Menubar";
import menuitems from "./Menuitem";
function Header() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light font-weight-bold">
        <a className="navbar-brand" href="/">
          <i className="fa fa-newspaper-o"></i> Let's Confer
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {menuitems.map(function(menuitem, index) {
              return (
                <Menubar
                  key={index}
                  name={menuitem.name}
                  url={menuitem.url}
                  faicon={menuitem.faicon}
                  extraclass={menuitem.extraclass}
                />
              );
            })}
            </ul>
{/*               <form className="form-inline my-2 my-lg-0">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-dark" type="submit">
                  <i className="fa fa-search"></i> Search
                </button>
              </form> */}
          
        </div>
      </nav>
    </header>
  );
}

export default Header;
