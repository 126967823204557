import React from "react";

function Menubar(props) {
  return (
    <li className="nav-item">
              <a className= {props.extraclass} href={props.url}>
                <i className={props.faicon}></i>{props.name}
              </a>
      </li>
  );
}

export default Menubar;
