const skills = [
    {
      "Area": "Internet of Things",
      "Skill": "Sensors & Actuators",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "MCUs - Arduino",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "MCUs - Raspberry Pi",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "MCUs - NodeMCU",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "MCUs - Particle Photon Board",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "Networking - Wifi, AT command, BLE, RF, MQTT, Websocket",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "IOT Cloud - Particle",
      "Score": "⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "IOT Cloud - Oracle",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "IOT Cloud - AWS",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "CNC Technology and programming",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "3D Printing and CAD design",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "Drone development & Programming",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "PCB Design",
      "Score": "⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "Advance Electronics",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Internet of Things",
      "Skill": "Certifcation in IoT (Axelta)",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Machine Learning",
      "Skill": "Data Science",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Machine Learning",
      "Skill": "ML - mathematical models in Octave",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Machine Learning",
      "Skill": "ML - mathematical models in  Python (NumPy, SciPy and Scikit-Learn)",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Machine Learning",
      "Skill": "ML - Deep Learning, Nural Net, Tensorflow, Keras)",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Machine Learning",
      "Skill": "ML - certificates from Stanford university & IBM",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Block Chain",
      "Skill": "Hyperledger Block Chain - Oracle - OBCS",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Block Chain",
      "Skill": "Go Lan programming",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Virtual Private Assistant",
      "Skill": "Alexa voice",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Virtual Private Assistant",
      "Skill": "Chatbot - Recast.Ai",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Virtual Private Assistant",
      "Skill": "Oracle Chatbot",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Compute Cloud",
      "Skill": "Oracle Compute Cloud",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Compute Cloud",
      "Skill": "AWS, GCP, Godday",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Cloud Container Management",
      "Skill": "Docker, Docker-compose",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Mobile Apps",
      "Skill": "Oracle MAF, JET, Cordova, AndroidSDK",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "HTML5",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "CSS, FONT AWESOME, BOOTSTRAP 4",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "JAVASCRIPT, ES6, JQUERY",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "NODE JS, EXPRESS JS, EJS",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "KNOCKOUT JS, REQUIREJS",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "ANGULAR JS, ANGULAR 2-8, REACT JS",
      "Score": "⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "Python Flask",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "PASSPORT JS, BCRYPT, OAUTH2",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "P5JS, D3JS, CANVASSJS",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Web Technology",
      "Skill": "Oracle Apex, Oracle Jet",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Oracle Technologies",
      "Skill": "SQL,PLSQL, Workflow, JAVA,OAF, Forms & Reports",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Analytics",
      "Skill": "Oracle Command Center",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Analytics",
      "Skill": "ENDECA",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Analytics",
      "Skill": "Hybrid - D3Js, CanvasJs, Matplotlib, Seaborn, Micro Strategy, Apache Superset",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Database Technology",
      "Skill": "Oracle RDBMS",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Database Technology",
      "Skill": "MONGODB",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Database Technology",
      "Skill": "MySql, PostgreSql",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Bigdata",
      "Skill": "Hadoop",
      "Score": "⭐⭐⭐"
    },
    {
      "Area": "Version Control",
      "Skill": "GITHub",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Testing Tool",
      "Skill": "Jmeter, OATS, Lint tools",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Unix",
      "Skill": "Ubuntu",
      "Score": "⭐⭐⭐⭐⭐"
    },
    {
      "Area": "Unix",
      "Skill": "REHL",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Unix",
      "Skill": "Raspbian",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "Unix",
      "Skill": "HP/UX",
      "Score": "⭐⭐⭐"
    },
    {
      "Area": "Web & Application server",
      "Skill": "Weblogic, Apache, NGINX, Nodejs",
      "Score": "⭐⭐⭐⭐"
    },
    {
      "Area": "REST APIs",
      "Skill": "ISG, ORDS, FMW",
      "Score": "⭐⭐⭐⭐"
    }
  ];
const functional = [
        {
            "Area": "Product",
            "Skill": "Oracle E-business from 10.7sc to 12.2.7",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Product",
            "Skill": "Oracle Transportation Management(6.2 to 6.4.3) – OnPrem & Logistic Cloud",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Product",
            "Skill": "Oracle Global Transportation Management (6.2 to 6.4.3) ",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Discrete and Process Manufacturing ",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Advance Supply chain planning",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Enterprise Asset management and tracking",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Field services",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Transportation & global trade management",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Procurement to Pay",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Order to Cash",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Service & Installed Base",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle RLM & EDI, Oracle XML Gateway",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle General Ledger and Subledger accounting",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Integrated SOA gateway",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle India, Brazil Localization",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Modules",
            "Skill": "Oracle Mobile Apps and Oracle Command Center",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Domain",
            "Skill": "Manufacturing and Distribution ",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Domain",
            "Skill": "Metal & Mining",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Domain",
            "Skill": "Energy and Utilities ",
            "Score": "⭐⭐⭐⭐⭐"
        },
        {
            "Area": "Domain",
            "Skill": "Communication & Media ",
            "Score": "⭐⭐⭐⭐⭐"
        }
  ];
  export default skills;
  export { functional };
