import React from "react";

function Footer() {
  return (
    <section className="bottom-container">
      <p className="copyright">© 2020 Subhendu Datta Bhowmik,CSCP.</p>
    </section>
  );
}

export default Footer;
